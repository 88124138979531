import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Link } from "gatsby";
import cx from "classnames";
import { Image } from "src/components/image";
import { AddButton } from "src/components/product/addButton";

const RelatedCard = ({
  shopify,
  main,
  index,
  clp,
  borderRight = true
}: {
  shopify: {
    defaultPrice: string;
  };
  borderRight: boolean;
  main: {
    colorAssociation: string;
    cleanTitle: string;
    subTitle: string;
    slug: {};
  };
}) => {
  const {
    cleanTitle,
    subTitle,
    slug,
    singleImage,
    colorAssociation,
    popupFacts
  } = main;
  return (
    <div
      className={cx(
        "w-full product__card hover:text-white border-black relative keen-slider__slide p-8 800:w-1/4",
        main.colorAssociation,
        {
          "border-r-2": borderRight,
          "1000:border-r-0": index === 3
        }
      )}
    >
      {main.singleImage && (
        <Image
          imageId={main.singleImage.asset._id}
          className="w-10/12 mb-4 mx-auto"
          alt={`${main.cleanTitle} Cereal`}
        />
      )}
      <Link
        className="block relative z-20"
        to={`/products/${main.slug.current}`}
      >
        <h2 className="text-40 offlimits text-white uppercase">
          {main.cleanTitle}
        </h2>
        {main.subTitle && (
          <h3 className="text-40 offlimits uppercase">{main.subTitle}</h3>
        )}
      </Link>
      <Link
        className="absolute z-10 w-full h-full top left"
        to={`/products/${main.slug.current}`}
      />
      {clp && clp.hoverImage && (
        <Image
          imageId={clp.hoverImage.asset._id}
          className="w-full opacity-0 duration-200 product__card-hover h-full absolute inset-0"
          alt={`${main.cleanTitle} Cereal`}
        />
      )}
      <div className="product__card-button mt-2 relative z-10">
        <div className="w-full text-black">
          <AddButton
            {...shopify}
            color={colorAssociation}
            sub={subTitle}
            image={singleImage.asset._id}
          />
        </div>
      </div>
      
    </div>
  );
};

export const RelatedProducts = ({ products }: { products: any[] }) => {
  console.log(products);
  const [sliderRef] = useKeenSlider({
    loop: true,
    slidesPerView: 1.2,
    centered: true,
    breakpoints: {
      "(min-width: 800px)": {
        slidesPerView: 2.5
      },
      "(min-width: 1000px)": {
        centered: false,
        slidesPerView: 4,
        loop: false
      }
    }
  });

  return (
    <div className="border-t-2 border-black border-solid">
      <div className="w-full keen-slider" ref={sliderRef}>
        {products.map((product, i) => (
          <RelatedCard
            shopify={product.content.shopify}
            clp={product.content.clp}
            index={i}
            main={product.content.main}
          />
        ))}
      </div>
    </div>
  );
};
