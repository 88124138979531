import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import { Image } from 'src/components/image'

export const HeroCarousel = ({ images, title }: {
  images: []
  title: string
}) => {

  const [slide, setSlide] = useState(1)
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slideChanged(s) {
      setSlide(s.details().relativeSlide)
    },
  })
  return (
    <div className='w-full bg-black relative 800:h-screen product__carousel overflow-hidden'>
      <div className='w-full keen-slider' ref={sliderRef}>
        {images && images.map(({
          asset
        }, i) => (
          <div className='w-full flex keen-slider__slide' key={i}>
            <Image imageId={asset._id} src={asset.url} className='w-full' alt={title} />
          </div>
        ))}
      </div>
      <div className='absolute bottom p-3 hidden 800:block'>
        {images && images.map(({
          asset
        }, i) => (
          <div onClick={() => slider.moveToSlide(i)} className={cx('w-14 h-14 mb-2 border-2 rounded-full relative overflow-hidden border-solid', {
            'border-black': i !== slide,
            'border-white': i === slide
          })} key={i}>
            <Image imageId={asset._id} src={asset.url} className='w-full block absolute h-full' alt={title} />
          </div>
        ))}
      </div>
      <div className='absolute right bottom p-3 hidden 800:block'>
        <div className='w-32 mx-auto 800:ml-1 inline-block'>
          <div className='flex items-center justify-between '>
            <button onClick={() => slider.prev()} className='bg-yellow h-14 w-14 border-2 border-solid border-black rounded-full flex items-center justify-center'>
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path d="M4.83008 7.35547L2.18725 4.7094L4.83008 2.06333" stroke="#24211E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
            <button onClick={() => slider.next()} className='bg-yellow h-14 w-14 border-2 border-solid border-black rounded-full flex items-center justify-center'>
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path d="M4.11035 2.06445L6.75318 4.71052L4.11035 7.35659" stroke="#24211E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-center p-2 600:hidden border-b border-white border-opacity-50'>
        {images && images.map((empty, i) => (
          <div key={i} className={cx('h-3 w-3 border border-solid border-white rounded-full m-1', {
            'bg-white': i === slide
          })} />
        ))}
      </div>
    </div>
  )
}