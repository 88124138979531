import React, { useEffect } from 'react'
import cx from 'classnames'

import { RenderModules } from 'src/utils/renderModules'

import {Marqy} from 'marqy'
import { ProductPurchase } from 'src/components/product/purchase'
import { HeroCarousel } from 'src/components/product/heroCarousel'
import { ProductSchema } from 'src/components/product/schema'
import { RelatedProducts } from 'src/components/product/relatedProducts'

import { useSetPage } from 'src/context/siteContext'
import { SEO } from 'src/components/SEO'
import { trackPageView } from "src/components/analytics"

export interface ProductProps {
  pageContext: {
    main: {
      modules: [],
      slug: {
        current: string
      },
      colorAssociation?: string
      title: string
      relatedProducts?: []
    },
    meta: {}
    shopify: {}
  }
  transitionStatus: string
}

const Product = ({
  pageContext
}: ProductProps) => {
  const setPage = useSetPage()
  const { main } = pageContext
  const {
    main: {
      modules,
      slug,
      title,
      colorAssociation,
      relatedProducts,
      heroMarquee
    },
    meta,
    shopify
  } = pageContext
  useEffect(() => {
    setPage(pageContext.main.slug.current)
    trackPageView(title)
  }, [0])
  const url = `products/${slug.current}`

  const marquee = heroMarquee && (
    <Marqy className='text-white font-14 mono uppercase bg-black'>&nbsp;{heroMarquee}&nbsp;</Marqy>
  )

  return (
    <div className={cx("ac overflow-hidden 800:overflow-visible")}>
      <div dangerouslySetInnerHTML={{
        __html: `
          <script type="application/ld+json">
            ${JSON.stringify(ProductSchema(pageContext.main, shopify))}
          </script>
        `
      }} />
      <SEO metaInfo={meta} pagePath={url} />
      <div className={cx('mxa x al ', pageContext.main.slug.current, colorAssociation)}>
        <div className='grid 1200:flex 1200:items-start 1200:flex-row-reverse w-full relative grid-cols-1 1000:grid-cols-11'>
          <div className='1000:col-span-4 self-start 1000:sticky 1000:top-10 1400:col-span-4 relative z-10 product__desc'>
            <div className='1000:hidden mt-4'>
              <HeroCarousel images={pageContext.main.heroCarousel} title={pageContext.main.title} />
              {marquee}
            </div>
            <div className='self-start 1000:sticky top-0'>
              <ProductPurchase main={pageContext.main} product={shopify} facts={pageContext.main.popupFacts}  />
            </div>
          </div>
          <div className='1000:col-span-7 1400:col-span-7 1000:border-r-2 product__content bg-gray border-solid border-black 1000:row-start-1'>
            <div className='hidden 1000:block'>
              <HeroCarousel images={pageContext.main.heroCarousel} title={pageContext.main.title} />
              {marquee}
            </div>
            {RenderModules(modules, main, shopify)}
          </div>
        </div>
      </div>
      {relatedProducts && (
        <RelatedProducts products={relatedProducts} />
      )}
    </div>
  )
}

export default Product
